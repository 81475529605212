import { useQuery } from '@tanstack/react-query';

import ReactQueryKeys from '../keys';
import { apiV1, get } from '../utils';

import type { GetAllReturn } from '~/types/get-all-return.type';
import type { Session } from '~/types/session.type';

const getSessions = get<GetAllReturn<Session>>(apiV1, '/sessions');

const useGetSessions = (page = 1, limit = 10, filters = {}) => {
  return useQuery({
    queryFn: () => getSessions({ filters: { page, limit, ...filters } }),
    queryKey: [ReactQueryKeys.GET_SESSIONS, page, limit, filters],
    staleTime: 1000,
    keepPreviousData: true,
  });
};

export default useGetSessions;
