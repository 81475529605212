import { darken } from 'polished';

export const studentOptionColors = [
  '#0CAE85',
  '#5E38BA',
  '#858c94',
  '#e9ad8c',
  '#7cc6d6',
  darken(0.2, '#0CAE85'),
  darken(0.3, '#5E38BA'),
  darken(0.2, '#858c94'),
  darken(0.2, '#e9ad8c'),
  darken(0.2, '#7cc6d6'),
  '#299452',
  '#2c3a4b',
  '#2f80ed',
  '#228174',
  '#e1604d',
  darken(0.2, '#299452'),
  darken(0.1, '#2c3a4b'),
  darken(0.2, '#2f80ed'),
  darken(0.1, '#228174'),
  darken(0.1, '#e1604d'),
  '#278b3d',
  '#b99333',
  '#4f5aac',
  '#6d7580',
  '#545d69',
  darken(0.2, '#278b3d'),
  darken(0.2, '#b99333'),
  darken(0.2, '#4f5aac'),
  darken(0.2, '#6d7580'),
  darken(0.2, '#545d69'),
];
