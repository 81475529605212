import { useMutation, useQueryClient } from '@tanstack/react-query';

import ReactQueryKeys from '../keys';
import { apiV1, patch } from '../utils';

const startSession = patch(apiV1, `/sessions/:id/start`);

const useStartSession = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => startSession({ paramsMap: { id }, body: null }),
    mutationKey: [ReactQueryKeys.START_SESSION],
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GET_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_ENGAGEMENT_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_OVERVIEW]);
    },
  });
};

export default useStartSession;
