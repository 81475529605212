import styled from 'styled-components';

export const SessionContainer = styled.article<{ isOngoing: boolean }>`
  &:not(:last-child) {
    margin-bottom: 1.6rem;
    padding-bottom: 1.6rem;
    border-bottom: 1px solid rgb(var(--color-neutral7));
  }
  ${({ isOngoing }) =>
    isOngoing && '&>button{background-color: rgb(var(--color-error));}'}
`;

export const SessionTitle = styled.p`
  color: rgb(var(--color-neutral1));

  & > span {
    text-transform: capitalize;
    font-weight: 600;
  }
`;

export const SessionTimes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.6rem;
  margin: 1.6rem 0;
`;

export const SessionTime = styled.p`
  padding: 0.8rem 1rem;
  text-align: center;
  color: rgb(var(--color-neutral4));
  flex-basis: 50%;

  & > span {
    display: block;

    &:first-child {
      font-size: 2.1rem;
      text-transform: lowercase;
      margin-bottom: 5px;
      font-weight: 600;
    }

    &:last-child {
      font-size: 1.1rem;
      text-transform: capitalize;
    }
  }
`;

export const SessionDuration = styled.p`
  padding: 0.8rem 1rem;
  text-transform: capitalize;
  text-align: center;
  margin-bottom: 3.2rem;
  background-color: rgb(var(--color-neutral8));
  border-radius: 5px;

  & > span {
    display: block;

    &:first-child {
      margin-bottom: 5px;
      font-size: 4.8rem;
      font-weight: 600;
    }

    &:last-child {
      font-size: 1.1rem;
    }
  }
`;
