import { SubmitHandler, useForm } from 'react-hook-form';

import {
  Button,
  Form,
  FormInputContainer,
  InputFooterText,
  ShowView,
} from '@gate-academy/react';

import useEndSession from '~/react-query/mutations/useEndSession';

interface IEndSessionFormInputTypes {
  endedBecause?: string;
}

interface IEndSessionProps {
  closeSidebar: () => void;
  showQuestion?: boolean;
  sessionId: string;
}

const EndSession = ({
  closeSidebar,
  showQuestion,
  sessionId,
}: IEndSessionProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IEndSessionFormInputTypes>({
    defaultValues: {
      endedBecause: '',
    },
  });

  const { mutate: endSession, isLoading } = useEndSession(
    sessionId,
    closeSidebar
  );

  const onSubmit: SubmitHandler<IEndSessionFormInputTypes> = (data) => {
    endSession({ endedBecause: data.endedBecause || undefined });
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ShowView when={!!showQuestion}>
        <FormInputContainer isInputInvalid={!!errors?.endedBecause}>
          <label htmlFor="endedBecause">Why is this class ending early?</label>

          <textarea
            id="endedBecause"
            {...register('endedBecause', {
              required: showQuestion && 'Please enter a reason',
            })}
          />

          {errors?.endedBecause && (
            <InputFooterText text={errors?.endedBecause?.message as string} />
          )}
        </FormInputContainer>

        <Button block isLoading={isLoading} loadingText="Ending...">
          End session
        </Button>
      </ShowView>

      <ShowView when={!showQuestion}>
        <p>Are you sure you want to end this session now?</p>

        <div style={{ marginTop: '3.2rem' }}>
          <Button block isLoading={isLoading} loadingText="Ending...">
            Yes
          </Button>

          <Button
            style={{ marginTop: '1.6rem' }}
            type="button"
            block
            transparentBg
            onClick={closeSidebar}
          >
            No
          </Button>
        </div>
      </ShowView>
    </Form>
  );
};

export default EndSession;
