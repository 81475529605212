import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';

import ReactQueryKeys from '../keys';
import { apiV1, patch } from '../utils';

type EndSessionPayload = {
  endedBecause?: string;
};

const endSession = patch<EndSessionPayload>(apiV1, '/sessions/:id/end');

const useEndSession = (id: string, closeSidebar: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: EndSessionPayload) =>
      endSession({ paramsMap: { id }, body: data }),
    mutationKey: [ReactQueryKeys.END_SESSION, id],
    onSuccess: () => {
      queryClient.invalidateQueries([ReactQueryKeys.GET_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_ENGAGEMENT_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_OVERVIEW]);

      toast.success('Session ended successfully');

      closeSidebar();
    },
  });
};

export default useEndSession;
