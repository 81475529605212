import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import { useQueryClient } from '@tanstack/react-query';

import { Button, ShowView, SidebarDetail } from '@gate-academy/react';

import { Session, SessionStatusEnum } from '~/types/session.type';

import {
  SessionContainer,
  SessionDuration,
  SessionTime,
  SessionTimes,
  SessionTitle,
} from './upcoming-session.styles';

import EndSession from '../end-session/end-session.component';

import useStore from '~/store';

import useStartSession from '~/react-query/mutations/useStartSession';

import {
  MERIDIAN_TIME_FORMAT,
  capitalizeFirstLetter,
} from '@gate-academy/shared/utils';

import ReactQueryKeys from '~/react-query/keys';

interface IUpcomingSessionProps {
  session: Session;
}

dayjs.extend(advancedFormat);
dayjs.extend(duration);
dayjs.extend(isBetween);

const isSessionStartable = (session: Session) => {
  return dayjs().isBetween(
    dayjs(session.startAt).subtract(5, 'minutes'),
    dayjs(session.startAt).add(session.duration, 'minutes'),
    'minutes',
    '[]'
  );
};

const getSessionDuration = (session: Session) => {
  let duration = session.duration * 60;

  if (session.status === SessionStatusEnum.ONGOING) {
    duration = dayjs(session.shouldEndAt).diff(dayjs(), 'seconds');
  }

  return duration;
};

const UpcomingSession = ({ session }: IUpcomingSessionProps) => {
  const [canSessionStart, setCanSessionStart] = useState(
    isSessionStartable(session)
  );
  const [duration, setDuration] = useState(getSessionDuration(session));

  const isTutor = useStore((state) => state.isTutor);

  const { mutate: startSession, isLoading } = useStartSession(session._id);

  const isOngoing = session.status === SessionStatusEnum.ONGOING;

  const isSessionToday = dayjs(session.startAt).isSame(dayjs(), 'day');

  const queryClient = useQueryClient();

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (!isTutor) return;

    interval = setInterval(() => {
      setCanSessionStart(isSessionStartable(session));
    }, 1000);

    return () => {
      if (interval && isTutor) {
        clearInterval(interval);
      }
    };
  }, [session, isTutor]);

  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;

    if (session.shouldEndAt) {
      interval = setInterval(() => {
        setDuration(getSessionDuration(session));
      }, 1000);
    }

    if (duration === 0) {
      // these basically just make sure that whenever these hooks enter the
      // foreground, react-query fires off a request to make sure the data is
      // up to date
      queryClient.invalidateQueries([ReactQueryKeys.GET_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_ENGAGEMENT_SESSIONS]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_OVERVIEW]);

      queryClient.invalidateQueries([ReactQueryKeys.GET_UNPAGINATED_SESSIONS]);

      if (interval) {
        clearInterval(interval);
      }
    }

    return () => {
      if (interval && session.shouldEndAt) {
        clearInterval(interval);
      }
    };
  }, [session, queryClient, duration]);

  return (
    <SessionContainer isOngoing={isOngoing}>
      <SessionTitle>
        <span>
          {capitalizeFirstLetter(session?.tutee?.lastName)}{' '}
          {capitalizeFirstLetter(session?.tutee?.firstName)}
        </span>
        's class{' '}
        {!isTutor && (
          <>
            with{' '}
            <span>
              {capitalizeFirstLetter(session?.tutor?.lastName)}{' '}
              {capitalizeFirstLetter(session?.tutor?.firstName)}
            </span>
          </>
        )}{' '}
        {isOngoing ? (
          <span
            style={{
              textTransform: 'lowercase',
              fontWeight: 400,
              display: 'inline-block',
              width: 'fit-content',
              overflow: 'initial',
              cursor: 'initial',
              padding: '0 5px',
            }}
            className="event-item ongoing-class"
          >
            is ongoing
          </span>
        ) : (
          <>
            will begin {isSessionToday ? ' ' : 'on '}
            <span>
              {dayjs(session.startAt).format(
                dayjs(session.startAt).isSame(dayjs(), 'day')
                  ? '[today]'
                  : 'ddd, Do MMMM, YYYY'
              )}
            </span>
          </>
        )}
      </SessionTitle>

      <SessionTimes>
        <SessionTime>
          <span>
            {dayjs(isOngoing ? session.startedAt : session.startAt).format(
              MERIDIAN_TIME_FORMAT
            )}
          </span>

          <span>{isOngoing ? 'Started at' : 'Start time'}</span>
        </SessionTime>

        <SessionTime>
          <span>
            {(isOngoing
              ? dayjs(session.shouldEndAt)
              : dayjs(session.startAt).add(session.duration, 'minutes')
            ).format(MERIDIAN_TIME_FORMAT)}
          </span>

          <span>{isOngoing ? 'Should end at' : 'End time'}</span>
        </SessionTime>
      </SessionTimes>

      <SessionDuration>
        <span>{dayjs.duration(duration, 'seconds').format('HH:mm:ss')}</span>

        <span>Duration</span>
      </SessionDuration>

      <ShowView when={isTutor}>
        <ShowView when={!isOngoing}>
          <Button
            block
            disabled={!canSessionStart}
            isLoading={isLoading}
            loadingText="Starting..."
            onClick={() => {
              startSession();
            }}
          >
            Start class
          </Button>
        </ShowView>

        <ShowView when={isOngoing}>
          <SidebarDetail
            heading="End session?"
            trigger={
              <Button danger block disabled={!duration}>
                End class
              </Button>
            }
          >
            {(close) => (
              <EndSession
                closeSidebar={close}
                sessionId={session._id}
                showQuestion={
                  !dayjs().isSame(dayjs(session.shouldEndAt), 'minutes')
                }
              />
            )}
          </SidebarDetail>
        </ShowView>
      </ShowView>
    </SessionContainer>
  );
};

export default UpcomingSession;
