import type { PopulatedUser } from './user.type';

export enum SessionStatusEnum {
  FINISHED = 'finished',
  UPCOMING = 'upcoming',
  MISSED = 'missed',
  ONGOING = 'ongoing',
  CANCELED = 'canceled',
  RESCHEDULED = 'rescheduled',
}

const sessionStatuses = Object.values(SessionStatusEnum);

export type SessionStatus = (typeof sessionStatuses)[number];

export interface Session {
  autoEnded?: boolean;
  canceledOn?: Date;
  billableSeconds?: number;
  client: string;
  createdAt: Date;
  duration: number;
  endedAt?: Date;
  endedBecause?: string;
  engagement: string;
  id: string;
  markedAsFinishedBy?: string;
  markedAsUpcomingBy?: string;
  modeOfClass: 'physical' | 'virtual';
  parentSession?: string;
  rescheduledBy?: string;
  rescheduledOn?: Date;
  shouldEndAt?: Date;
  startAt: Date;
  startedAt?: Date;
  status: SessionStatus;
  tutee: PopulatedUser;
  tutor: PopulatedUser;
  updatedAt: Date;
  _id: string;
}
