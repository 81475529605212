import { useQuery } from '@tanstack/react-query';

import ReactQueryKeys from '../keys';
import { apiV1, get } from '../utils';

import useStore from '~/store';

import { Overview } from '~/types/overview.type';

const getOverview = get<Overview>(apiV1, '/users/:id/overview');

const useGetOverview = () => {
  const currentUser = useStore((state) => state.currentUser);

  const currentUserId = currentUser?.id as string;

  return useQuery({
    queryKey: [ReactQueryKeys.GET_OVERVIEW, currentUserId],
    queryFn: () => getOverview({ paramsMap: { id: currentUserId } }),
    enabled: !!currentUserId,
    keepPreviousData: true,
  });
};

export default useGetOverview;
